import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Section, Container } from "../components/Containers"

const ThankYou = () => (
  <Layout language="en" navSpacerLarge>
    <SEO title="Thank You" description="Thank you" robots="noindex" />

    <Section>
      <Container>
        <h1>Thank you!</h1>
        <p style={{ marginBottom: "20vh" }}>
          We appreciate your generous contribution to BRIG.
        </p>
      </Container>
    </Section>
  </Layout>
)

export default ThankYou
